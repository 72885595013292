export const AppConfig = {
  FILES_ENDPOINT: process.env.REACT_APP_FILES_ENDPOINT, // For the static files path
  APP_ENDPOINT: process.env.REACT_APP_ENDPOINT,
  SOCKET_ENDPOINT: process.env.REACT_APP_SOCKET_ENDPOINT,
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  MAPBOX_ACCESS_TOKEN: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT,
  API_VERSION: process.env.REACT_APP_API_VERSION,
  DEFAULT_DATE_FORMAT: process.env.REACT_APP_DEFAULT_DATE_FORMAT,
  NEW_SERVER_API_URL: "https://webstagebackend.clarity.codes/v1", //process.env.NEW_API_URL,
  NEW_M2_SERVER_API_URL: "https://55ac-223-236-199-251.ngrok-free.app/v1",
};
