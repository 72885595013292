import React from "react";
import { AppConfig } from "../../../config";
import editIcon from "../../../assets/img/edit-3.svg";
import editIconWhite from "../../../assets/img/edit-3-white.svg";
import trashIcon from "../../../assets/img/trash-2.svg";
import trashIconWhite from "../../../assets/img/trash-2-white.svg";
import dummyImage from "../../../assets/img/noimage.jpeg";
import { Button } from "react-bootstrap";
import "./product.css";

const ProductRow = ({ productData, onExpand, onEdit, onDelete }) => {
  const {
    productName = "-",
    productNumber = "-",
    productDescription = "-",
    productImage = "-",
    isExpand = false,
    otherImages = [],
  } = productData;

  return (
    <tr>
      <td>{productNumber}</td>
      <td className="pname-desc"> {productName} </td>
      <td className="pre-desc">
        {productDescription && productDescription.length <= 100 ? (
          productDescription
        ) : (
          <p className="mb-0">
            {isExpand ? productDescription : productDescription.substr(0, 100)}
            <span className="bold-desc cursor-pointer" onClick={onExpand}>
              {!isExpand ? "...Read More" : "...Read less"}
            </span>
          </p>
        )}
        {/* {productDescription}
        <span className='bold-desc'> Read More</span> */}
      </td>
      <td className="productImageHolder">
        {productImage ? (
          <div className="productImage">
            <img
              className="list-img"
              src={
                productImage
                  ? productImage.startsWith("https://")
                    ? productImage
                    : `${AppConfig.FILES_ENDPOINT}${productImage}`
                  : ""
              }
              alt=""
            />
            {otherImages.length > 0 && (
              <span className="otherImageCount">
                {otherImages.length > 0 ? "+" + otherImages.length : ""}
              </span>
            )}
          </div>
        ) : (
          <div className="tableTextSplit">
            {productName
              .split(" ")
              .slice(0, 2)
              .map((el) => el[0].toUpperCase())}
          </div>
        )}
      </td>
      <td>
        <Button
          className="btn btn-secondary th-btn edit-tb action-block-btn"
          onClick={onEdit}
        >
          <span>
            <img src={editIcon} alt="" className={"img-main"} />
            <img src={editIconWhite} alt="" className={"img-hover"} />
          </span>
          Edit
        </Button>
        <Button
          variant="danger"
          className="btn th-btn delete-tb action-block-btn"
          onClick={onDelete}
        >
          <span>
            <img src={trashIcon} alt="" className={"img-main"} />
            <img src={trashIconWhite} alt="" className={"img-hover"} />
          </span>
          Delete
        </Button>
      </td>
    </tr>
  );
};

export default ProductRow;
