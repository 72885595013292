import React from "react";
import moment from "moment";
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from "../../../config";
import listIcon from "../../../assets/img/list.svg";
import editIcon from "../../../assets/img/edit-3.svg";
import editIconWhite from "../../../assets/img/edit-3-white.svg";
import trashIcon from "../../../assets/img/trash-2.svg";
import trashIconWhite from "../../../assets/img/trash-2-white.svg";
import { Button } from "react-bootstrap";
import { InputTextField, SubmitButton } from "../forms";

export const CounterfeitIdRow = ({
  scanData,
  userColumn = true,
  batchViewHandler,
  userViewHandler,
  onPrint,
  onEdit,
  onDelete,
  onChange,
}) => {
  const {
    scanId = "",
    batch = {},
    product = {},
    user = {},
    // user_name = '',
    // location,
    createdAt,
  } = scanData;
  const { batchNumber = "" } = batch || {};
  const { productName = "" } = product || {};
  const { first_name = "", other_info = {} } = user || {};
  return (
    <>
      {/* Add New QR Row */}
      {/* <tr>
        <td style={{ color: "#0A1F44" }} colspan="3">
          <div style={{ maxWidth: "342px" }}>
            <InputTextField
              id="first_name"
              className={"form-control-user"}
              name="first_name"
              value={""}
              onChange={onChange}
              maxLength={30}
              placeholder="Enter code ID here"
            />
          </div>
        </td>
        <td style={{ color: "#0A1F44" }} colspan="1">
          <div
            className="d-flex align-items-start"
            style={{
              width: "100%",
            }}
          >
            <Button
              variant="danger"
              className="btn th-btn delete-tb action-block-btn pl-2"
              onClick={onDelete}
            >
              Cancel
            </Button>
            <Button
              className="btn btn-secondary th-btn edit-tb action-block-btn pl-2 pr-2"
              onClick={onEdit}
            >
              Add
            </Button>
          </div>
        </td>
      </tr> */}
      <tr>
        <td
          className={"cursor-pointer"}
          style={{ color: "#0A1F44" }}
          // onClick={batchViewHandler ? batchViewHandler : null}
        >
          XYZ345
        </td>
        <td>09:47 Hrs | 30/12/2020</td>
        <td className="pname_scan">PHL Admin</td>
        <td
          className={"cursor-pointer"}
          // onClick={batchViewHandler ? batchViewHandler : null}
        >
          <div
            className="d-flex align-items-start"
            style={{
              width: "100%",
            }}
          >
            <Button
              className="btn btn-secondary th-btn edit-tb action-block-btn"
              onClick={onEdit}
            >
              <span>
                <img src={editIcon} alt="" className={"img-main"} />
                <img src={editIconWhite} alt="" className={"img-hover"} />
              </span>
              Edit
            </Button>
            <Button
              variant="danger"
              className="btn th-btn delete-tb action-block-btn"
              onClick={onDelete}
            >
              <span>
                <img src={trashIcon} alt="" className={"img-main"} />
                <img src={trashIconWhite} alt="" className={"img-hover"} />
              </span>
              Delete
            </Button>
          </div>
        </td>
      </tr>
    </>
  );
};
