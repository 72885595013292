export const AppRoutes = {
  HOME: "/",
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  CLARITY: "/clarity",
  COUNTERFEIT: "/clarity/counterfeit/counterfeit-requests",
  COUNTERFEIT_VIEW: "/clarity/counterfeit/counterfeit-requests/:id",
  COUNTERFEIT_MAP_VIEW: "/clarity/counterfeit/counterfeit-requests/:id/map",
  SCANS: "/clarity/counterfeit/scans",
  ALERTS: "/clarity/counterfeit/alerts",
  SCAN_RULES: "/clarity/counterfeit/scan-rules",
  Add_SCAN_RULES: "/clarity/counterfeit/scan-rules/add-scan-rules",
  Edit_SCAN_RULES: "/clarity/counterfeit/scan-rules/edit-scan-rules/:id",
  COUNTERFEIT_ID: "/clarity/counterfeit/scan-rules/counterfeit_id",

  MANAGE_USER: "/clarity/users",
  ADD_USER: "/clarity/users/add",
  VIEW_USER: "/clarity/users/:id",
  VIEW_VIDEO: "/clarity/videos/details",
  MANAGE_VIDEO_CATEGORY: "/clarity/video/category",
  MANAGE_VIDEO: "/clarity/video/category/:id",
  SETTING: "/settings",
  MANAGE_PRODUCT: "/products",
  ADD_PRODUCT: "/products/add",
  EDIT_PRODUCT: "/products/edit/:id",
  MANAGE_BATCH: "/batches",
  ADD_BATCH: "/batches/add",
  EDIT_BATCH: "/batches/edit/:id",
  VIEW_BATCH: "/batches/view/:id",
  PRINT_BATCH: "/batches/:id/print/:unit",
  MANAGE_ORIGIN: "/origins",
  BATCHES: "/batches",
  MANAGE_CLIENTS: "/clients",
  MANAGE_CLIENTS_REQUESTS: "/clients/requests",
  MANAGE_CLIENTS_INVITATION: "/clients/invitation",
  VIEW_CLIENT_REQUEST: "/clients/requests/view/:id",
  VIEW_CLIENT_BATCH: "/clients/view/batch/:id/product/:productId",
  MANAGE_CLIENTS_PERMISSION: "/clients/view/permissions/:id",
  VIEW_CLIENT: "/clients/view/:id",
  CLIENT_SUBSCRIPTION: "/clients/view/subscription/:id",
  MANAGE_STAFF: "/staff",
  MANAGE_STAFF_LIST: "/staff/list",
  MANAGE_STAFF_ROLE: "/staff/role",
  MANAGE_STAFF_REQUESTS: "/staff/list/onboarding",
  VIEW_STAFF_REQUEST: "/staff/list/requests/view/:id",
  MANAGE_STAFF_INVITATION: "/staff/list/invitation",
  MANAGE_STAFF_PERMISSION: "/staff/list/view/permissions/:id",
  VIEW_STAFF: "/staff/list/view/:id",
  EDIT_CLIENT_REQUEST: "/clients/requests/edit/:id",
  TERMS: "/terms",
  PRIVACY_POLICY: "/privacy_policy",
  USER_DATA_DELETION: "/user_data_deletion",
  CONTACT: "contact",
  CONTACT_USER: "/cms/:user/contact",
  CONTACT_APP: "/cms/contact-app",
  CONTACT_CLIENT: "/cms/contact-client",
  CONTACT_STAFF: "/cms/contact-staff",
  TERMS_AND_CONDITIONN: "terms",
  TERMS_AND_CONDITION: "/cms/terms-app",
  TERMS_AND_CONDITION_USER: "/cms/:user/terms",
  TERMS_AND_CONDITION_CLIENT: "/cms/terms-client",
  TERMS_AND_CONDITION_STAFF: "/cms/terms-staff",
  CMS_PRIVACY_POLICYY: "policy-app",
  CMS_PRIVACY_POLICY_USER: "/cms/:user/policy",
  CMS_PRIVACY_POLICY: "/cms/policy-app",
  CMS_PRIVACY_POLICY_CLIENT: "/cms/policy-client",
  CMS_PRIVACY_POLICY_STAFF: "/cms/policy-staff",
  CMS_VIDEO_TUTORIAL: "/cms/:user/tutorial",
  FAQS: "faqs",
  FAQS_USERS: "/cms/:user/faqs",
  FAQS_APPUSERS: "/cms/faqs-app",
  FAQS_CLIENTSUSERS: "/cms/faqs-client",
  FAQS_CATEGORY: "/cms/faq/:user/faqs-category",
  FAQS_STAFFUSERS: "/cms/faqs-staff",
  PUSH_NOTIFICATIONS: "/cms/push-notifications",
  REPORTS: "/reports",
  BATCHES_REPORTS: "/reports/batches",
  COUNTERFEIT_REPORTS: "/reports/reports-counterfeits",
  PRODUCT_REPORTS: "/reports/products",
  USERS_REPORTS: "/reports/reportusers",
  SCAN_REPORTS: "/reports/reports-scans",
  MANAGE_CLARITY: "clarity",
  CMS: "/cms",
  MANAGE_REPORTS: "/reports",
  TEST: "test_page",
  TEST_ONE: "/test_page/test_one",
  TEST_TWO: "/test_page/test_two",
  MANAGE_SUBSCRIPTION: "/subscription",
  PLANS: "/subscription/plans",
  PLANS_PAID: "/subscription/plans/paid",
  PLANS_CUSTOM: "/subscription/plans/custom",
  PLANS_CONTACT: "/subscription/plans/contact",
  ADDPLAN: "/subscription/plans/add-plan",
  ADDCUSTOM: "/subscription/plans/add-custom",
  Edit_plan: "/subscription/plans/edit-plan/:id",
  Edit_custom_plan: "/subscription/plans/edit-custom-plan/:id",
  TRANSACTIONS: "/subscription/transactions",
  TRANSACTIONSVIEW: "/subscription/transactions/:id",
  PLANVIEW: "/subscription/plans/planview/:id",
  CUSTOMVIEW: "/subscription/plans/customview/:id",
  SUBSCRIBED_CLIENTS: "/subscription/subscribed-list",
  SUBSCRIBEDVIEW: "/subscription/subscribed-list/subscribed-view/:id",
  INVOICE: "/transactions/invoice/:id",
  DISCOUNT: "/subscription/discount",
  LANDING_PAGE_CLIENT: "/cms/:user/landing-page",
  ABOUT_US: "/cms/:user/about-us",
  ENQUIRIES: "/enquiries",
  ENQUIRIES_SALES: "/enquiries/sales",
  ENQUIRIES_GENERAL: "/enquiries/general",
  ENQUIRIES_CAREERS: "/enquiries/careers",
  ENQUIRIES_GENERAL_DETAILS: "/enquiries/general/details/:id",
  ENQUIRIES_CAREERS_DETAILS: "/enquiries/careers/details/:id",
  ENQUIRIES_SALES_DETAILS: "/enquiries/sales/details/:id",
  CAREERS: "/cms/:user/careers",
  ADDJOB_CAREERS: "/cms/:user/careers/add-job",
  EDITJOB_CAREERS: "/cms/:user/careers/edit-job/:id",
  JOBTITLE_CAREERS: "/cms/:user/careers/job-title",
  ACCOUNT_MANAGEMENT: "/cms/:user/account-management",
  ADDTOPIC_ACCOUNT_MANAGEMENT: "/cms/:user/account-management/add-topic",
  EDITTOPIC_ACCOUNT_MANAGEMENT: "/cms/:user/account-management/edit-topic/:id",
  MODULE_LISTING_ACOUNT_MANAGEMENT: "/cms/:user/account-management/module-list",
};
